import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import useRoles from '../../../../../app/utils/roles/roles'
import useCorporationAccess from '../../../../../app/utils/roles/corporationAccess'
import { useAuth } from '../../../../../app/modules/auth'


const SidebarMenuMain = () => {
  const {
    canAccessExperiences,
    canAccessManagement,
    canAccessAdministracion,
    canAccessMarket
  } = useCorporationAccess();
  
  const {
    isAdminCorporation, 
    isDesarrollador, 
    isSuperAdminHolos, 
    isAreaManager, 
    isInstructor
  } = useRoles()

  const { newCurrentUser } = useAuth()
  console.log('canAccessAdministracion', canAccessAdministracion)
  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title='Dashboard'
        fontIcon='bi-app-indicator'
      />
      {/* Experiences */}
      {(isSuperAdminHolos || isInstructor || isAdminCorporation || isAreaManager) && canAccessExperiences ? (
        <SidebarMenuItemWithSub
          to='/experiences'
          title='Experiencias'
          icon='abstract-28'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem
            to='/experiences/experiences'
            title={isSuperAdminHolos?'Experiencias':'Mis experiencias'}
            hasBullet={true}
          />
          { isInstructor || isAdminCorporation || isAreaManager ? (
            <SidebarMenuItem
              to='/experiences/dashboardBI'
              title='Mis Tableros BI'
              hasBullet={true}
            />
          ) : null}
        </SidebarMenuItemWithSub>
      ) : null}

      {/* Administration */}
      { (isSuperAdminHolos || isDesarrollador) && canAccessAdministracion && (
        <SidebarMenuItemWithSub
          to='/administracion'
          title='Administración'
          icon='setting'
          fontIcon='bi-layers'
        >
          <SidebarMenuItem to='/administracion/usuarios' title='Usuarios' hasBullet={true} />
          
          { isSuperAdminHolos || isDesarrollador ? (
            <>
              <SidebarMenuItem to='/administracion/empresas' title='Empresas' hasBullet={true} />
            </>
          ) : null}
          { isSuperAdminHolos || isDesarrollador || isAdminCorporation ? (
            <>
              <SidebarMenuItem to='/administracion/contratistas' title='Contratistas' hasBullet={true} />
            </>
          ) : null}

          {/* <SidebarMenuItem to='/administracion/simuladores' title='Simuladores' hasBullet={true} /> */}

          {isAdminCorporation ? (
            <SidebarMenuItem to='/administracion/gerencias' title='Gerencias/Áreas' hasBullet={true} />
          ) : null}
        </SidebarMenuItemWithSub>
      )}
      
      {/* Interactive Content */}
      { (isSuperAdminHolos || isDesarrollador) && canAccessAdministracion && (
        <SidebarMenuItemWithSub
          to='/interactive-content'
          title='Contenido Interactivo'
          icon='setting'
          fontIcon='bi-layers'
        >
          { isSuperAdminHolos || isDesarrollador ? (
            <>
              <SidebarMenuItem to='/interactive-content/simuladores' title='Simuladores' hasBullet={true} />
            </>
          ) : (
            <>
            <SidebarMenuItem to='/interactive-content/mis-simuladores' title='Mis Simuladores' hasBullet={true} />
          </>
          )}


          {/* <SidebarMenuItem to='/administracion/simuladores' title='Simuladores' hasBullet={true} /> */}

        </SidebarMenuItemWithSub>
      )}

      {/* Managment */}
      { (isSuperAdminHolos || isInstructor || isAdminCorporation || isAreaManager) && canAccessManagement && (
        <SidebarMenuItemWithSub to='/gestion' title='Gestión' icon='element-7' fontIcon='bi-layers'>
        <SidebarMenuItem
          to={
              isSuperAdminHolos
                ? '/gestion/suscripciones'
                : (isAdminCorporation || (isInstructor && newCurrentUser?.isFreelance) || isAreaManager)
                  ? '/gestion/suscripciones/ver'
                  : ''
          }         
          title='Suscripciones'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/gestion/training-campaigns'
          title='Campañas de entrenamiento'
          hasBullet={true}
        />
          {/* <SidebarMenuItem to='/gestion/distributions' title='Listas de Distribución' hasBullet={true} /> */}
          {/* <SidebarMenuItem to='/gestion/reportBI' title='Tableros BI' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>
      )}

      {/* MarketPlace */}
      {/* { canAccessMarket && (
        <SidebarMenuItemWithSub to='/marketplace' title='Market Place' icon='element-7' fontIcon='bi-layers'>
        <SidebarMenuItem
          to='/marketplace/simulators'
          title='Simuladores'
          hasBullet={true}
        />
        </SidebarMenuItemWithSub>
      )} */}
      
      {/* Config */}
      {isSuperAdminHolos ? (
        <SidebarMenuItemWithSub
          to='/configuration'
          title='Configuración'
          icon='abstract-26'
          fontIcon='bi-layers'
        >
          {/* <SidebarMenuItem to='/configuration/params' title='Parametros' hasBullet={true} /> */}
          <SidebarMenuItem to='/configuration/adminBI' title='Administrador BI' hasBullet={true} />
          {/* <SidebarMenuItem to='/configuration/adminRol' title='Administrador Role' hasBullet={true} /> */}
        </SidebarMenuItemWithSub>
      ) : null}
    </>
  )
}

export {SidebarMenuMain}
